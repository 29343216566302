import React from "react";
import LinkButton from "../LinkButton/LinkButton";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import PropTypes from "prop-types";
import * as TitleColumnsStyles from "./TitleColumns.module.scss";
import {graphql} from "gatsby";

const TitleColumns = ({ block }) => {
  const title = block.title;
  const columns = block.columns;

  return (
    <Section row="r-top" width="normal">
      <div className="col sm-12 m-4 mb-3">
        <p className="text48 sm-12 m-12">{title}</p>
      </div>
      <div className="col sm-12 m-8">
        <div className="row">
          {columns.map((column) => {
            const { title, text } = column;
            const link = column.link[0];
            return (
              <div key={column.id} className="col sm-12 m-4 mb-7">
                <h3 className="mb-5">{title}</h3>
                {text && (
                  <p className="mb-5 subtle">{text}</p>
                )}
                {link && (
                  <div className={TitleColumnsStyles.columnLink}>
                    <LinkButton
                      type={link.linkType}
                      text={link.text}
                      isExternal={link.pressForExternalLinking}
                      buttonSize={link.buttonSize}
                      internalLink={link.slug}
                      externalLink={link.externalLink}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default TitleColumns;

TitleColumns.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string,
        link: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired,
            url: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          })
        ),
      })
    ).isRequired,
  }),
};

export const query = graphql`
  fragment TitleColumns on DatoCmsTitlethreecolum {
    model {
      apiKey
    }
    id: originalId
    title
    columns {
      id: originalId
      title
      text
      link {
        ...Link
      }
    }
  }
`